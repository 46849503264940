import React, { useState } from 'react';
import { useEffect } from 'react';
import { Database } from 'sql.js';
import OutputTable from './outputtable';

interface SqlQueryTableProps {
  db?: Database | null;
  query?: string | null;
  limit?: number | null;
}

function SqlQueryTable({db, query, limit}: SqlQueryTableProps) {
  const [results, setResults] = useState<{[key: string]:any}[] | null>(null);
  const [columns, setColumns] = useState<string[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    if(!db || !query) {
      setResults(null);
      return;
    }

    let tmpQuery = query;
    if(limit) {
      tmpQuery += `\nLIMIT ${limit}`;
    }

    (async () => {
      try {
        const res = db.exec(tmpQuery)[0];
        const rows = res.values.map((values) => {
          const row: {[key: string]:any} = {}
          res.columns.forEach((col, index) => row[col] = values[index]);
          return row;
        });

        setResults(rows);
        setColumns(res.columns);
        setError(null);
      }
      catch(e) {
        console.log('SQL Error:', e + '');
        setError(e + '');
      }
    })();
  }, [db, query, limit]);

  if(error) {
    return <div className='flex flex-col items-center justify-center h-full text-red-400'>
      <span>{error}</span>
    </div>
  }
  if(!results || !columns) {
    return <div className='flex flex-col items-center justify-center h-full text-gray-400'>
      No data available...
    </div>
  }

  return <OutputTable data={results} columns={columns} />
}

export default SqlQueryTable