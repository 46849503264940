import React, { FunctionComponent } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faTable,
  faFileSpreadsheet,
  faDatabase,
  faFilter,
  faSort,
  faCodeMerge,
  faLayerGroup,
  faUnion,
  faTv,
} from '@fortawesome/pro-light-svg-icons'

import SidebarItem from './sidebaritem';
import {
  createDataSourceNode,
  createOutputNode,
  createSingleOperationNode,
} from './nodes'
import { NodeData } from '../models/graphtypes';

export type SidebarItemDesc = {
  id: string;
  name: string;
  tooltip: string;
  icon: IconProp;
  defaultData: NodeData;
  nodeFactory: (desc: any) => FunctionComponent<any>;
}

export const sidebarItems: { [key: string]: SidebarItemDesc[]} = {
  'Data Sources': [
    {
      id: 'table',
      name: 'Table',
      tooltip: 'Database Table',
      icon: faTable,
      defaultData: {
        name: ''
      },
      nodeFactory: createDataSourceNode,
    },
    {
      id: 'file',
      name: 'File',
      tooltip: 'CSV, JSON, or Parquet File',
      icon: faFileSpreadsheet,
      defaultData: {
        tempTableName: ''
      },
      nodeFactory: createDataSourceNode,
    },
    {
      id: 'query',
      name: 'Query',
      tooltip: 'Another Databse Query',
      icon: faDatabase,
      defaultData: {
        query: ''
      },
      nodeFactory: createDataSourceNode,
    },
  ],
  'Operations': [
    {
      id: 'filter',
      name: 'Filter',
      tooltip: 'Filter Rows',
      icon: faFilter,
      defaultData: {
        fragment: ''
      },
      nodeFactory: createSingleOperationNode,
    },
    {
      id: 'sort',
      name: 'Sort',
      tooltip: 'Sort Rows',
      icon: faSort,
      defaultData: {
        fragment: ''
      },
      nodeFactory: createSingleOperationNode,
    },
    {
      id: 'join',
      name: 'Join',
      tooltip: 'Join two or more data sources',
      icon: faCodeMerge,
      defaultData: {
        fragment: ''
      },
      nodeFactory: createSingleOperationNode,
    },
    {
      id: 'group',
      name: 'Group',
      tooltip: 'Use with aggregation functions',
      icon: faLayerGroup,
      defaultData: {
        fragment: ''
      },
      nodeFactory: createSingleOperationNode,
    },
    {
      id: 'append',
      name: 'Append',
      tooltip: 'Append two data sources, one after the other',
      icon: faUnion,
      defaultData: {
        fragment: ''
      },
      nodeFactory: createSingleOperationNode,
    },
  ],
  'Output': [
    {
      id: 'display',
      name: 'Display',
      tooltip: 'Output',
      icon: faTv,
      defaultData: {},
      nodeFactory: createOutputNode,
    },
  ]
};

export const sidebarItemsByType = (() => {
  let nodes: { [key: string]: SidebarItemDesc } = {}

  for(let [, items] of Object.entries(sidebarItems)) {
    for(let item of items) {
      nodes[item.id] = item;
    }
  }

  return nodes;
})();

function Sidebar() {
  return (
    <ul className='pt-4 select-none'>
      {Object.entries(sidebarItems).map(([section, items]) => (
        <li key={section} className='pb-4'>
          <span className='ml-2 font-bold text-white'>{section}</span>
          <ul>
            {items.map(item => (
              <li key={`${section}-${item.id}`}>
                <SidebarItem item={item}/>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  )
}

export default Sidebar