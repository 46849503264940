import initSqlJs, { Database } from 'sql.js';
const sqlWasm = require('!!file-loader?name=sql-wasm-[contenthash].wasm!sql.js/dist/sql-wasm.wasm');

export async function init(): Promise<Database> {
  const SQL = await initSqlJs({ locateFile: () => sqlWasm.default });
  const db = await downloadDb();
  return new SQL.Database(new Uint8Array(db));
}

async function downloadDb() {
  const response = window.fetch('/data.sqlite');
  const data = await (await response).arrayBuffer();
  return data;
}
