import {
  createStore,
  createTypedHooks,
} from 'easy-peasy';
import model, { Model } from './model';

const Store = createStore(model);

if (process.env.NODE_ENV === 'development') {
  if ((module as any)['hot']) {
    console.log('Store hot-reload enabled');
    (module as any)['hot'].accept('./model', () => {
      Store.reconfigure(model);  // 👈 Here is the magic
    });
  }
}

export const {
  useStoreActions,
  useStoreState,
  useStoreDispatch,
  useStore
} = createTypedHooks<Model>();

export default Store;