import { Elements, getIncomers, Node } from 'react-flow-renderer';
import { FilterData, NodeData, SortData, TableData } from '../models/graphtypes';

export enum QueryStatus {
  OK = 'ok',
  ERROR = 'error'
}

export type NodeErrors = {[key: string]: string};

export interface SqlQueryOk {
  status: QueryStatus.OK;
  query: string;
}
export interface SqlQueryError {
  status: QueryStatus.ERROR;
  errorString: string;
  nodeErrors: NodeErrors;
}
export type SqlQuery = SqlQueryOk | SqlQueryError;

function queryErr(msg: string, nodeErrors: NodeErrors = {}): SqlQuery {
  return {
    status: QueryStatus.ERROR,
    errorString: msg,
    nodeErrors,
  }
}

export interface SqlColumn {
  name: string,
  alias?: string,
}
export interface SqlFormula {
  expression: string,
  alias?: string,
}
export type SqlSelectColumn = SqlColumn | SqlFormula;

export enum SqlJoinType {
  NONE = 'none',
  INNER = 'inner',
  OUTER = 'outer'
}
export interface SqlTable {
  name: string;
  alias?: string;
  joinType?: SqlJoinType;
  joinByFragment?: string;
}
export interface SqlNestedQuery {
  query: SqlQueryRepr;
  alias?: string;
  joinType?: SqlJoinType;
  joinByFragment?: string;
}
export type SqlFromTable = SqlTable | SqlNestedQuery;

interface SqlWhereClause {
  fragments: string[];
}

interface SqlOrderByClause {
  fragment: string;
}

interface SqlQueryRepr {
  select: SqlSelectColumn[];
  from: SqlFromTable[];
  where: SqlWhereClause;
  orderBy?: SqlOrderByClause;
}

function selectColumnToString(column: SqlSelectColumn) {
  let name = '';
  if('name' in column) {
    name = column.name;
  }
  else {
    name = `${(column.expression)}`
  }

  if(column.alias) {
    name += ` AS '${column.alias}'`
  }
  return name;
};

function fromTableToString(table: SqlTable): any {
  const alias = table.alias ? ` AS ${table.alias} ` : ''
  let join = '';

  switch(table.joinType) {
    case undefined:
    case null:
    case SqlJoinType.NONE:
      break;
    case SqlJoinType.INNER:
      join = ` JOIN BY ${table.joinByFragment}`
      break;
    case SqlJoinType.OUTER:
      join = ` OUTER JOIN BY ${table.joinByFragment}`
      break;
    default:
      throw Error(`Unkown join type: ${table.joinType}`);
  }

  return `${table.name}${alias}${join}`
}

function nestedQueryToString(table: SqlNestedQuery): any {
  throw new Error('Function not implemented.');
}

function whereClauseToString(where: SqlWhereClause) {
  return where.fragments.join('\n AND ');
}

function orderByClauseToString(orderBy: SqlOrderByClause) {
  return orderBy.fragment;
}

function reprToString(repr: SqlQueryRepr): string {
  let output = 'SELECT ' + repr.select.map((field) => {
    return selectColumnToString(field);
  }).join(', ') + '\n';

  output += 'FROM ' + repr.from.map((table) => {
    if('name' in table) {
      return fromTableToString(table);
    }
    else {
      return nestedQueryToString(table);
    }
  });

  if(repr.where.fragments.length > 0) {
    output += '\nWHERE ' + whereClauseToString(repr.where);
  }

  if(repr.orderBy) {
    output += '\nORDER BY ' + orderByClauseToString(repr.orderBy);
  }

  return output;
}

function handleTable(node: Node<TableData>, repr: SqlQueryRepr) {
  if(!node.data)
    return;
  repr.select.push({
    name: `${node.data.name}.*`
  });
  repr.from.push({
    name: node.data.name
  });
}

function handleFilter(node: Node<FilterData>, repr: SqlQueryRepr) {
  if(!node.data || !node.data.fragment)
    return;
  repr.where.fragments.push(node.data.fragment);
}

function handleSort(node: Node<SortData>, repr: SqlQueryRepr) {
  if(!node.data || !node.data.fragment)
    return;
  repr.orderBy = {
    fragment: node.data.fragment
  }
}

export function generateQuery(elements: Elements<NodeData>): SqlQuery {
  const repr: SqlQueryRepr = {
    select: [],
    from: [],
    where: {
      fragments: []
    },
  }

  const outputNodes = elements.filter((node) => node.type === 'display');
  if(outputNodes.length === 0) {
    return queryErr('There needs to be at least one output');
  }
  if(outputNodes.length > 1) {
    return queryErr('Only one output node is allowd');
  }
  const outputNode = outputNodes[0] as Node<NodeData>;
  
  let incomers = getIncomers(outputNode, elements);
  if(incomers.length === 0) {
    return queryErr('Nothing connected to the output node');
  }
  if(incomers.length > 1) {
    return queryErr('More than one node connected to the output');
  }

  do {
    const node = incomers[0];
    switch(node.type) {
      case 'table':
        handleTable(node, repr);
        break;
      case 'filter':
        handleFilter(node, repr);
        break;
      case 'sort':
        handleSort(node, repr);
        break;
      default:
        return queryErr(`Unexpected node type ${node.type}`);
    }

    incomers = getIncomers(node, elements);
    if(incomers.length > 1) {
      return queryErr(`More than one node connected to ${node.type}`);
    }
    console.log('Incomers', incomers);
  } while(incomers.length > 0)

  const query = reprToString(repr);

  return {
    status: QueryStatus.OK,
    query
  }
}
