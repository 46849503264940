import React, { SyntheticEvent } from 'react'
import { QueryData } from '../../models/graphtypes';
import { useStoreActions } from '../../models/store';
import { NodePageProps } from '../nodeeditpage';

function QueryPage({ node }: NodePageProps) {
  const data = node.data as QueryData;
  const queryName = data?.query;

  const {
    updateElement,
  } = useStoreActions((actions) => actions);

  const onChange = (e: SyntheticEvent<HTMLInputElement>) => {
    node.data = {
      query: e.currentTarget.value,
    }
    updateElement(node);
  };

  return <div>
    <h3>Query</h3>
    <div>
      Query: 
      <input type='text' value={queryName} onInput={onChange} />
    </div>
  </div>
}

export default QueryPage;