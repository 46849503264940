import React, { FunctionComponent } from 'react';
import { Node } from 'react-flow-renderer';
import { NodeData } from '../models/graphtypes';
import { useStoreState } from '../models/store';
import AppendPage from './nodepages/appendpage';
import DisplayPage from './nodepages/displaypage';
import FilterPage from './nodepages/filterpage';
import GroupPage from './nodepages/grouppage';
import JoinPage from './nodepages/joinpage';
import QueryPage from './nodepages/querypage';
import SortPage from './nodepages/sortpage';
import TablePage from './nodepages/tablepage';

export interface NodePageProps {
  node: Node<NodeData>;
}

const nodePages: {[key: string]: FunctionComponent<NodePageProps>} = {
  table: TablePage,
  display: DisplayPage,
  query: QueryPage,
  filter: FilterPage,
  sort: SortPage,
  join: JoinPage,
  group: GroupPage,
  append: AppendPage,
};

function NodeEditPage() {
  const selectedNode = useStoreState((state) => state.selectedNode);
  const node = useStoreState((state) => state.elements).find((node) => node.id === selectedNode) as Node<NodeData>;
  if(!selectedNode || !node) {
    return <></>
  }

  const nodeType = node.type || '';

  if(!nodePages[nodeType])
    return <></>

  return React.createElement(nodePages[nodeType], { node })
}

export default NodeEditPage;