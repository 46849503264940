import React, { useContext } from 'react';
import { AppContext, AppContextProps } from '../../App';
import { useStoreState } from '../../models/store';
import { QueryStatus } from '../../utilities/querygenerator';
import { NodePageProps } from '../nodeeditpage';
import SqlQueryTable from '../sqlquerytable';

function DisplayPage({ node: _node }: NodePageProps) {
  const appContext: AppContextProps = useContext(AppContext);  
  const query = useStoreState((state) => state.generatedQuery);

  if(query.status === QueryStatus.OK) {
    return <SqlQueryTable db={appContext.db} query={query.query} limit={25}/>
  }
  else {
    return <div className='flex flex-col items-center justify-center h-full text-red-400'>
      <span>{query.errorString}</span>
    </div>
  }
}

export default DisplayPage;