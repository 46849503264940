import React, { SyntheticEvent } from 'react'
import { SortData } from '../../models/graphtypes';
import { useStoreActions } from '../../models/store';
import { NodePageProps } from '../nodeeditpage';

function SortPage({ node }: NodePageProps) {
  const data = node.data as SortData;
  const fragment = data?.fragment;

  const {
    updateElement,
  } = useStoreActions((actions) => actions);

  const onChange = (e: SyntheticEvent<HTMLInputElement>) => {
    node.data = {
      fragment: e.currentTarget.value,
    }
    updateElement(node);
  };

  return <div>
    <h3>Sort</h3>
    <div>
      Name: 
      <input type='text' value={fragment} onInput={onChange} />
    </div>
  </div>
}

export default SortPage;