import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {SidebarItemDesc, sidebarItemsByType} from './sidebar';
import TooltipContainer from './tooltipcontainer';
import { NodeData } from '../models/graphtypes';

export interface NodeDragData {
  nodeType: string;
  data: NodeData;
}

interface SidebarItemProps {
  item: SidebarItemDesc;
};

function SidebarItem({item}: SidebarItemProps) {
  const onDragStart = (event: React.DragEvent<HTMLElement>, nodeType: string) => {
    event.dataTransfer.setData('application/reactflow', 
      JSON.stringify({ nodeType, data: sidebarItemsByType[nodeType].defaultData } as NodeDragData)
    );
    event.dataTransfer.effectAllowed = 'move';
  }

  return (
    <TooltipContainer tooltipText={item.tooltip} className='mt-1 ml-2 mr-2'>
      <div
        className='pl-1 pr-1 rounded-lg hover:bg-blue-500 cursor-move'
        draggable
        onDragStart={(event) => onDragStart(event, item.id)}
      >
        <span className='text-white font-thin text-sm'>
          <FontAwesomeIcon className='mr-2' fixedWidth icon={item.icon}/>
          {item.name}
        </span>
      </div>
    </TooltipContainer>
  )
}

export default SidebarItem;