import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Handle,
  Position,
  Node
} from 'react-flow-renderer';
import { NodeData } from '../models/graphtypes';
import { SidebarItemDesc } from './sidebar';

export function createDataSourceNode(desc: SidebarItemDesc) {
  return function DataSourceNode({ id, data }: Node<NodeData>) {
    return (
      <div className="flex flex-col items-center">
        <div className='flex flex-col w-16 h-12 p-2 items-center justify-items-center rounded shadow bg-white query-node'>
          <Handle type="source" position={Position.Right} style={{ top: '25px' }}/>
          <FontAwesomeIcon className='fa-2x' fixedWidth icon={desc.icon}/>
        </div>
        <span className="text-xs mt-2">{(data as any)?.name || desc.name}</span>
      </div>
    )
  }
}


export function createSingleOperationNode(desc: SidebarItemDesc) {
  return function SingleOperationNode({ id }: any) {
    return (
      <div className="flex flex-col items-center">
        <div className='flex flex-col w-16 h-12 p-2 items-center justify-items-center rounded shadow bg-white query-node'>
          <Handle type="target" position={Position.Left}  style={{ top: '25px' }} />
          <FontAwesomeIcon className='fa-2x' fixedWidth icon={desc.icon}/>
          <Handle type="source" position={Position.Right}  style={{ top: '25px' }} />
        </div>
      </div>
    )
  }
}

export function createOutputNode(desc: SidebarItemDesc) {
  return function OutputNode({ id }: any) {
    return (
      <div className="flex flex-col items-center">
        <div className='flex flex-col w-16 h-12 p-2 items-center justify-items-center rounded shadow bg-white query-node'>
          <Handle type="target" position={Position.Left}  style={{ top: '25px' }} />
          <FontAwesomeIcon className='fa-2x' fixedWidth icon={desc.icon}/>
        </div>
        <span className="text-xs mt-2">Output</span>
      </div>
    )
  }
}
