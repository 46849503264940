import React, { useState, useRef, useMemo } from 'react';
import ReactFlow, {
  Controls,
  OnLoadParams,
  NodeTypesType,
  FlowElement,
  FlowTransform,
  Elements,
  Node,
  isNode
} from 'react-flow-renderer';
import { v4 as uuidv4 } from 'uuid';

import { sidebarItemsByType } from './sidebar';
import createRemovableEdge from './edges';
import { load, save } from '../models/querystore';
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../models/store';
import { useMountEffect } from '../utilities/hooks';
import { NodeData } from '../models/graphtypes';
import { NodeDragData } from './sidebaritem';

function nodeTypes() {
  let nodes: NodeTypesType = {};

  for(let [id, item] of Object.entries(sidebarItemsByType)) {
    nodes[id] = item.nodeFactory(item);
  }

  return nodes;
}

function QueryGraph() {
  const {
    elements,
    flowTransform,
  } = useStoreState((state) => state);
  const {
    addNode,
    connectNodes,
    setElements,
    setFlowTransform,
    setSelectedNodes,
    removeElements,
    removeElementById,
    updateElement,
  } = useStoreActions((actions) => actions);
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<any | null>(null);
  const onLoad = (instance: OnLoadParams<any>) => setReactFlowInstance(instance as any);
  const onDragOver = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }
  const onDrop = (event: React.DragEvent<HTMLElement>) => {
    if(!reactFlowWrapper || !reactFlowWrapper.current || !reactFlowInstance) {
      return;
    }
    event.preventDefault();

    const bounds = reactFlowWrapper.current.getBoundingClientRect();
    const dragData = JSON.parse(event.dataTransfer.getData('application/reactflow')) as NodeDragData;
    const type = dragData.nodeType;
    const position = reactFlowInstance.project({
      x: event.clientX - bounds.left,
      y: event.clientY - bounds.top,
    });

    const newNode: Node<NodeData> = {
      id: uuidv4(),
      type,
      position,
      data: dragData.data,
    }

    console.log('WHAT', newNode, dragData);

    addNode(newNode);
  }
  const onNodeDrag = useMemo(() => {
    return (_event: any, node: FlowElement<NodeData>) => {
      updateElement(node);
    }
  }, [updateElement]);
  const onMove = useMemo(() => {
    return (transform: FlowTransform | undefined) => {
      if(transform) {
        setFlowTransform(transform);
      }
    }
  }, [setFlowTransform]);
  const onSelectionChanged = useMemo(() => {
    return (elements: Elements<NodeData> | null) => {
      if(!elements) {
        setSelectedNodes([]);
      }
      else {
        setSelectedNodes(elements.filter((element) => {
          return isNode(element);
        }).map((node) => node.id));
      }
    }
  }, [setSelectedNodes]);

  // Load
  const [loaded, setLoaded] = useState(false);
  useMountEffect(() => {
    load()
    .then(loadedState => {
      setElements(loadedState.elements);
      setFlowTransform(loadedState.flowTransform);
      console.log('Loaded', loadedState);
    })
    .catch(e => {
      console.error('Failed to load query', e);
    }).finally(() => {
      setLoaded(true);
    });
  });

  // Save
  useEffect(() => {
    if(!loaded)
      return;

    console.log('onSave', elements, flowTransform);

    save({
      elements,
      flowTransform,
    })
    .catch(e => {
      console.error('Failed to save query', e);
    });
  }, [elements, flowTransform, loaded])

  if(!loaded) {
    return <div />
  }

  return (
    <div className="w-full h-full" ref={reactFlowWrapper}>
      <ReactFlow
        elements={elements}
        defaultPosition={[flowTransform.x, flowTransform.y]}
        defaultZoom={flowTransform.zoom}
        nodeTypes={nodeTypes()}
        edgeTypes={{default: createRemovableEdge(removeElementById)}}
        deleteKeyCode={46}
        snapToGrid
        snapGrid={[50, 50]}
        onLoad={onLoad}
        onElementsRemove={removeElements}
        onConnect={connectNodes}
        onDragOver={onDragOver}
        onDrop={onDrop}
        onNodeDragStop={onNodeDrag}
        onMoveEnd={onMove}
        onSelectionChange={onSelectionChanged}
      >
        <Controls showInteractive={false} />
      </ReactFlow>
    </div>
  )
}

export default QueryGraph;