import React from 'react';

interface TooltipProps {
  tooltipText: string;
  [key: string]: any;
}

function TooltipContainer({tooltipText, children, className, ...props}: TooltipProps) {
  if(className) {
    className += ' tooltip'
  }
  else {
    className = 'tooltip'
  }
  
  return (
    <div data-text={tooltipText} className={className}  {...props}>
      {children}
    </div>
  )
}

export default TooltipContainer;