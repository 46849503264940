import localForage from 'localforage';
import { Elements, FlowTransform } from 'react-flow-renderer';
import { NodeData } from './graphtypes';

localForage.config({
  name: 'querybuilder',
  storeName: 'query',
});

interface QueryStore {
  elements: Elements<NodeData>;
  flowTransform: FlowTransform;
}

const QUERY_KEY = 'query/untitled';
const INITIAL_ELEMENTS: Elements<any> = [
  {
    id: '1',
    type: 'display',
    data: { },
    position: { x: 250, y: 50 },
  },
];

export async function load(): Promise<QueryStore> {
  const data: any = await localForage.getItem(QUERY_KEY);
  if(data) {
    if(!data.flowTransform) {
      data.flowTransform = {
        x: 0, y: 0, zoom: 1
      };
    }
    return data as QueryStore;
  }
  else {
    return {
      elements: INITIAL_ELEMENTS,
      flowTransform: {
        x: 0, y: 0, zoom: 1
      }
    };
  }
}

export async function save(query: QueryStore) {
  await localForage.setItem(QUERY_KEY, query);
}