import { addEdge, Connection, Edge, Elements, FlowTransform, Node, removeElements } from 'react-flow-renderer';
import { action, Action, computed, Computed } from 'easy-peasy';
import { NodeData } from './graphtypes';
import { generateQuery, SqlQuery } from '../utilities/querygenerator';

export interface Model {
  // Graph
  elements: Elements<NodeData>;
  removeElements: Action<Model, Elements<any>>;
  removeElementById: Action<Model, string>;
  connectNodes: Action<Model, Edge | Connection>;
  addNode: Action<Model, Node>;
  updateElement: Action<Model, Node | Edge>;
  setElements: Action<Model, Elements<NodeData>>;
  
  // Transform
  flowTransform: FlowTransform;
  setFlowTransform: Action<Model, FlowTransform>;

  // Selection
  selectedNodes: string[];
  selectedNode: Computed<Model, string | undefined>;
  setSelectedNodes: Action<Model, string[]>;

  // Query
  generatedQuery: Computed<Model, SqlQuery>;
}

const model: Model = {
  // Graph
  elements: [],
  removeElements: action((state, elements) => {
    state.elements = removeElements(elements, state.elements);
  }),
  removeElementById: action((state, id) => {
    state.elements = state.elements.filter((el) => {
      return el.id !== id;
    });
  }),
  connectNodes: action((state, connection) => {
    state.elements = addEdge(connection, state.elements);
  }),
  addNode: action((state, node) => {
    state.elements.push(node);
  }),
  updateElement: action((state, element) => {
    state.elements = state.elements.map((el) => {
      if(el.id === element.id) {
        return element;
      }
      else {
        return el;
      }
    });
  }),
  setElements: action((state, elements) => {
    state.elements = elements;
  }),

  // Transform
  flowTransform: {x: 0, y: 0, zoom: 1},
  setFlowTransform: action((state, transform) => {
    state.flowTransform = transform;
  }),

  // Selection
  selectedNodes: [],
  selectedNode: computed((state) => {
    if(state.selectedNodes.length === 1) {
      return state.selectedNodes[0]
    }
    else {
      return undefined;
    }
  }),
  setSelectedNodes: action((state, nodes) => {
    state.selectedNodes = nodes
  }),

  // Query
  generatedQuery: computed((state) => {
    const query = generateQuery(state.elements);
    console.log('Query', query);
    return query;
  }),
}

export default model;
