import React from 'react';
import {
  EdgeProps,
  getBezierPath,
  getEdgeCenter,
  getMarkerEnd,
} from 'react-flow-renderer';
import {
  faTimes
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const foreignObjectSize = 40;

const onEdgeClick = (evt: any, id: string, removeFunc: (id: string) => any) => {
  evt.stopPropagation();
  removeFunc(id);
};

export default function createRemovableEdge(removeFunc: (id: string) => any) {
  return function RemovableEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    data,
    arrowHeadType,
    markerEndId,
  }: EdgeProps) {
    const edgePath = getBezierPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
    });
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
      sourceX,
      sourceY,
      targetX,
      targetY,
    });

    return (
      <>
        <path
          id={id}
          style={style}
          className="react-flow__edge-path"
          d={edgePath}
          markerEnd={markerEnd}
        />
        <foreignObject
          width={foreignObjectSize}
          height={foreignObjectSize}
          x={edgeCenterX - foreignObjectSize / 2}
          y={edgeCenterY - foreignObjectSize / 2}
          className=""
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <body className="flex flex-col items-center justify-center w-full h-full">
            <button
              className="bg-gray-300 flex-0 rounded-2xl"
              style={{width: 18, height: 18}}
              onClick={(event) => onEdgeClick(event, id, removeFunc)}
            >
              <FontAwesomeIcon className='block fa-xs ml-auto mr-auto' icon={faTimes}/>
            </button>
          </body>
        </foreignObject>
      </>
    );
  }
}
