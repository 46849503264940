import React, { SyntheticEvent } from 'react'
import { FilterData } from '../../models/graphtypes';
import { useStoreActions } from '../../models/store';
import { NodePageProps } from '../nodeeditpage';

function FilterPage({ node }: NodePageProps) {
  const data = node.data as FilterData;
  const fragment = data?.fragment;

  const {
    updateElement,
  } = useStoreActions((actions) => actions);

  const onChange = (e: SyntheticEvent<HTMLInputElement>) => {
    node.data = {
      fragment: e.currentTarget.value,
    }
    updateElement(node);
  };

  return <div>
    <h3>Filter</h3>
    <div>
      Name: 
      <input type='text' value={fragment} onInput={onChange} />
    </div>
  </div>
}

export default FilterPage;