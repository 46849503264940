import React, { useMemo } from 'react';
import {
  useTable,
  Column,
  useResizeColumns,
} from 'react-table';

interface OutputTableProps {
  columns: string[];
  data: {[key: string]: any}[];
}

function OutputTable({columns, data}: OutputTableProps) {
  const columnDefs: Column<any>[] = useMemo(() => columns.map((item) => (
    {
      Header: item,
      accessor: item,
    }
  )), [columns]);

  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  )

  const tableInstance = useTable(
    {
      columns: columnDefs,
      data,
      defaultColumn,
    },
    useResizeColumns,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  return (
    <div className="w-full min-h-full">
      <table 
        {...getTableProps()}
        className="min-h-full min-w-full expand-last-column last-col-no-border border-collapse table-auto"
      >
        <thead className="text-left shadow bg-gray-50">
          {
          headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {
              headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} className="px-6 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                  {
                  column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
          {
          rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {
                  row.cells.map(cell => {
                    return (
                      <td{...cell.getCellProps()} className="px-6 py-2 text-sm text-gray-900 whitespace-nowrap">
                        {cell.render('Cell')}
                      </td>
                    );
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default OutputTable;