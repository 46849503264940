import React, { useState } from 'react';
import ResizePanel from 'react-resize-panel';

import Sidebar from './components/sidebar'
import QueryGraph from './components/querygraph';
import { init } from './models/db';
import { useEffect } from 'react';
import { Database } from 'sql.js';
import NodeEditPage from './components/nodeeditpage';
import { useMemo } from 'react';

export interface AppContextProps {
  db: Database | null;
}

export const AppContext = React.createContext<AppContextProps>({db: null});

function App() {
  const [db, setDb] = useState<Database | null>(null);
  useEffect(() => {
    init()
    .then((db) => {
      console.log('DB Initialized');
      setDb(db);
    })
    .catch((e: any) => {
      console.error('Failed to init DB', e);
    });
  }, []);

  const context = useMemo(() => {
    return {
      db,
    }
  }, [db]);

  return (
    <AppContext.Provider value={context}>
    <div className='flex flex-col min-h-screen min-w-screen'>
      <div className='h-8 bg-white'>

      </div>
      <div className='flex flex-grow flex-row min-w-screen'>
        <div className='w-32 bg-blue-400 shadow-inner border-r-2'>
          <Sidebar/>
        </div>
        <div className='flex flex-grow flex-col'>
          <div className='flex-grow bg-gray-100 shadow-inner'>
            <QueryGraph/>
          </div>
          <ResizePanel direction="n" style={{height: '200px'}}>
            <div className='flex-grow border-t-2 overflow-y-scroll'>
              <NodeEditPage />
            </div>
          </ResizePanel>
        </div>
      </div>
    </div>
    </AppContext.Provider>
  );
}

export default App;
